import React from 'react';
import { Box, Grid, TextField, Card, CardContent, MenuItem, FormControl, InputLabel, Select, Checkbox, FormControlLabel, Typography, RadioGroup, Radio, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';



const TransferRequestForm = () => {
  
  const navigate = useNavigate();  // React Router's navigation hook

 // New Requested Services Section State
 const [selectedCategory, setSelectedCategory] = React.useState('medical');
 const [requestedServices, setRequestedServices] = React.useState([]);

 const medicalOptions = ['Cardio', 'GI', 'ID', 'Nephro', 'Neuro', 'Onc', 'Pulm', 'Psych', 'Other'];
 const surgicalOptions = ['CT Surg', 'Gen Surg', 'Ortho', 'Urology', 'Vascular', 'Neuro Surg'];
 const procedureOptions = ['IR', 'CT', 'MRI', 'EEG', 'EMU', 'Cardio (TEE, TAVR)', 'Branch (EGD, Colo)'];
 const otherOptions = ['PT', 'OT', 'ST', 'Burn', 'Transplant'];

 const handleCategoryChange = (event) => {
   setSelectedCategory(event.target.value);
   setRequestedServices([]); // Reset services when category changes
 };

 const handleServiceChange = (event) => {
   const value = event.target.value;
   setRequestedServices((prev) => 
     prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
   );
 };

 const getServiceOptions = () => {
   switch (selectedCategory) {
     case 'medical':
       return medicalOptions;
     case 'surgical':
       return surgicalOptions;
     case 'procedure':
       return procedureOptions;
     case 'other':
       return otherOptions;
     default:
       return [];
   }
 };

  // Transfer Request Section State
  const [patientLocation, setPatientLocation] = React.useState('');
  const [facility, setFacility] = React.useState('');
  const [contactNumber, setContactNumber] = React.useState('');
  const [rnNumber, setRnNumber] = React.useState('');
  const [doctorNumber, setDoctorNumber] = React.useState('');
  const [transferType, setTransferType] = React.useState('lowToHigh');
  const [adult, setAdult] = React.useState(true);
  const [oneWay, setOneWay] = React.useState(true);
  const [telemedConsult, setTelemedConsult] = React.useState(false);

  // Transport Needs Section State
  const [transportGround, setTransportGround] = React.useState(false);
  const [transportAir, setTransportAir] = React.useState(false);
  const [specialHandling, setSpecialHandling] = React.useState(false);
  const [isolation, setIsolation] = React.useState(false);
  const [restraintViolent, setRestraintViolent] = React.useState(false);
  const [restraintNonViolent, setRestraintNonViolent] = React.useState(false);
  const [positioning, setPositioning] = React.useState(false);
  const [vip, setVip] = React.useState(false);
  const [equipment, setEquipment] = React.useState([]);
  const [enRouteMeds, setEnRouteMeds] = React.useState([]);

  // Demographics Section State
  const [name, setName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [dateAdmitted, setDateAdmitted] = React.useState('');
  const [codeStatus, setCodeStatus] = React.useState('');
  const [insurance, setInsurance] = React.useState('');
  const [familyContact, setFamilyContact] = React.useState('');

  // Communication Section State
  const [bedType, setBedType] = React.useState('');
  const [facilityMatch, setFacilityMatch] = React.useState('');
  const [requestedInfo, setRequestedInfo] = React.useState([]);
  const [communicationLog, setCommunicationLog] = React.useState('');

  const handleEquipmentChange = (event) => {
    const value = event.target.value;
    setEquipment((prev) => prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]);
  };

  const handleEnRouteMedsChange = (event) => {
    const value = event.target.value;
    setEnRouteMeds((prev) => prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]);
  };

  const handleRequestedInfoChange = (event) => {
    const value = event.target.value;
    setRequestedInfo((prev) => prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]);
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <Box  display="flex" height="90vh" overflow="hidden">
      {/* Navigation Sidebar */}
  <Box       flex="0 0 230px"
        bgcolor="#f0f0f0"
        p={2}
        position="sticky"
        top="0" // Keep the sidebar fixed at the top of the viewport
        height="90vh" // Ensure the sidebar takes up the full height of the page
   >
  <Typography variant="h6">Sections</Typography>
        <Button onClick={() => handleScrollToSection('transfer-request')} fullWidth sx={{ marginBottom: 1 }}>
          A. Transfer Request
        </Button>
        <Button onClick={() => handleScrollToSection('transport-needs')} fullWidth sx={{ marginBottom: 1 }}>
          B. Transport Needs
        </Button>
        <Button onClick={() => handleScrollToSection('demographics')} fullWidth sx={{ marginBottom: 1 }}>
          C. Demographics
        </Button>
        <Button onClick={() => handleScrollToSection('communication')} fullWidth sx={{ marginBottom: 1 }}>
          D. Communication
        </Button>
        <Button onClick={() => handleScrollToSection('requested-services')} fullWidth>
          E. Requested Services
        </Button>
      </Box>
      {/* Form Section */}
      <Box         flex="1"
        p={2}
        overflow="auto" // Make this section scrollable
        height="90vh" // Ensure it fills the vertical space
        >        <Card id="transfer-request" sx={{ marginBottom: 4 }}>
          <CardContent>
            <Typography variant="h5">Transfer Request</Typography>

            <Grid container spacing={2}>
              {/* Patient Location */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Patient Location"
                    value={patientLocation}
                    onChange={(e) => setPatientLocation(e.target.value)}
                    placeholder="Name of Facility (Dropdown to complete)"
                    required
                  />
                </FormControl>
              </Grid>

              {/* Facility */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Facility"
                    value={facility}
                    onChange={(e) => setFacility(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              {/* Contact Number */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Contact Number"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              {/* RN Number */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="RN Number"
                    value={rnNumber}
                    onChange={(e) => setRnNumber(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              {/* Doctor Number */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Doctor Number"
                    value={doctorNumber}
                    onChange={(e) => setDoctorNumber(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              {/* Transfer Type */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Transfer Type</InputLabel>
                  <Select
                    value={transferType}
                    onChange={(e) => setTransferType(e.target.value)}
                  >
                    <MenuItem value="lowToHigh">Low to High (Default)</MenuItem>
                    <MenuItem value="lateral">Lateral</MenuItem>
                    <MenuItem value="highToLow">High to Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Patient Type */}
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset" margin="normal">
                  <RadioGroup
                    row
                    value={adult ? 'adult' : 'pediatric'}
                    onChange={(e) => setAdult(e.target.value === 'adult')}
                  >
                    <FormControlLabel value="adult" control={<Radio />} label="Adult" />
                    <FormControlLabel value="pediatric" control={<Radio />} label="Pediatric" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Transfer Details */}
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset" margin="normal">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={oneWay}
                        onChange={(e) => setOneWay(e.target.checked)}
                      />
                    }
                    label="Oneway"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!oneWay}
                        onChange={(e) => setOneWay(!e.target.checked)}
                      />
                    }
                    label="Roundtrip"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={telemedConsult}
                        onChange={(e) => setTelemedConsult(e.target.checked)}
                      />
                    }
                    label="Telemed Consult"
                  />
                </FormControl>
              </Grid>
            </Grid>

          </CardContent>
          </Card>

          <Card id="transport-needs" sx={{ marginBottom: 4 }}>
            <CardContent>
            <Typography variant="h5">Transport Needs</Typography>

            <Grid container spacing={2}>
              {/* Ground Transportation */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Ground</Typography>
                <FormControlLabel
                  control={<Checkbox checked={transportGround} onChange={(e) => setTransportGround(e.target.checked)} />}
                  label="ALS"
                />
                <FormControlLabel
                  control={<Checkbox checked={false} />}
                  label="BLS"
                />
                <FormControlLabel
                  control={<Checkbox checked={false} />}
                  label="Medium"
                />
                <FormControlLabel
                  control={<Checkbox checked={false} />}
                  label="POV"
                />
              </Grid>

              {/* Air Transportation */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Air</Typography>
                <FormControlLabel
                  control={<Checkbox checked={transportAir} onChange={(e) => setTransportAir(e.target.checked)} />}
                  label="Fixed"
                />
                <FormControlLabel
                  control={<Checkbox checked={false} />}
                  label="Rotary"
                />
              </Grid>

              {/* Special Handling */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Special Handling</Typography>
                <FormControlLabel
                  control={<Checkbox checked={specialHandling} onChange={(e) => setSpecialHandling(e.target.checked)} />}
                  label="Special Handling"
                />
                <FormControlLabel
                  control={<Checkbox checked={isolation} onChange={(e) => setIsolation(e.target.checked)} />}
                  label="Isolation"
                />
                <FormControlLabel
                  control={<Checkbox checked={restraintViolent} onChange={(e) => setRestraintViolent(e.target.checked)} />}
                  label="Restraint - Violent"
                />
                <FormControlLabel
                  control={<Checkbox checked={restraintNonViolent} onChange={(e) => setRestraintNonViolent(e.target.checked)} />}
                  label="Restraint - Non Violent"
                />
                <FormControlLabel
                  control={<Checkbox checked={positioning} onChange={(e) => setPositioning(e.target.checked)} />}
                  label="Positioning"
                />
                <FormControlLabel
                  control={<Checkbox checked={vip} onChange={(e) => setVip(e.target.checked)} />}
                  label="VIP/Trauma"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Other"
                />
              </Grid>

              {/* Equipment */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Equipment</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="O2"
                      checked={equipment.includes('O2')}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="O2"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Vent"
                      checked={equipment.includes('Vent')}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="Vent/Bi-pap/c-pap"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Lines"
                      checked={equipment.includes('Lines')}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="Lines/Drains"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Impella"
                      checked={equipment.includes('Impella')}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="Impella"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="OtherEquipment"
                      checked={equipment.includes('OtherEquipment')}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="Other"
                />
              </Grid>

              {/* En Route Meds */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">En Route Meds</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Med1"
                      checked={enRouteMeds.includes('Med1')}
                      onChange={handleEnRouteMedsChange}
                    />
                  }
                  label="Medication 1"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Med2"
                      checked={enRouteMeds.includes('Med2')}
                      onChange={handleEnRouteMedsChange}
                    />
                  }
                  label="Medication 2"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Med3"
                      checked={enRouteMeds.includes('Med3')}
                      onChange={handleEnRouteMedsChange}
                    />
                  }
                  label="Medication 3"
                />
              </Grid>
            </Grid>
            </CardContent>
          </Card>

          <Card id="demographics" sx={{ marginBottom: 4 }}>
            <CardContent>
            <Typography variant="h5">Demographics</Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Date of Birth (DoB)"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Date Admitted"
                    value={dateAdmitted}
                    onChange={(e) => setDateAdmitted(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Code Status"
                    value={codeStatus}
                    onChange={(e) => setCodeStatus(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Insurance"
                    value={insurance}
                    onChange={(e) => setInsurance(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Family Contact"
                    value={familyContact}
                    onChange={(e) => setFamilyContact(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            </CardContent>
            </Card>
   
            
          <Card id="communication" sx={{ marginBottom: 4 }}>
            <CardContent>
            <Typography variant="h5">Communication</Typography>

            <Grid container spacing={2}>
              {/* Bed Type */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Bed Type</InputLabel>
                  <Select
                    value={bedType}
                    onChange={(e) => setBedType(e.target.value)}
                  >
                    <MenuItem value="medSurg">Med/Surg</MenuItem>
                    <MenuItem value="tele">TELE</MenuItem>
                    <MenuItem value="stepDown">Stepdown</MenuItem>
                    <MenuItem value="icu">ICU</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Facility Match */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Facility Match</InputLabel>
                  <Select
                    value={facilityMatch}
                    onChange={(e) => setFacilityMatch(e.target.value)}
                  >
                    <MenuItem value="hospitalA">Hospital A</MenuItem>
                    <MenuItem value="hospitalB">Hospital B</MenuItem>
                    <MenuItem value="hospitalC">Hospital C</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Requested Info */}
              <Grid item xs={12}>
                <Typography variant="subtitle1">Requested Information</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="faceSheet"
                      checked={requestedInfo.includes('faceSheet')}
                      onChange={handleRequestedInfoChange}
                    />
                  }
                  label="Face Sheet"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="pushImages"
                      checked={requestedInfo.includes('pushImages')}
                      onChange={handleRequestedInfoChange}
                    />
                  }
                  label="Push Images"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="mdToCall"
                      checked={requestedInfo.includes('mdToCall')}
                      onChange={handleRequestedInfoChange}
                    />
                  }
                  label="MD to Call"
                />
                {/* Additional checkboxes can be added here as needed */}
              </Grid>

              {/* Communication Log */}
              <Grid item xs={12}>
                <TextField
                  label="Communication Log"
                  multiline
                  rows={4}
                  fullWidth
                  value={communicationLog}
                  onChange={(e) => setCommunicationLog(e.target.value)}
                />
              </Grid>
            </Grid>

            </CardContent>
            </Card>
          
        {/* Existing sections like Transfer Request, Transport Needs, Demographics, Communication here */}

          
          <Card id="requested-services" sx={{ marginBottom: 4 }}>
            <CardContent>
            <Typography variant="h5">Requested Services</Typography>

            {/* Top-level Category Selection */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Service Category</InputLabel>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <MenuItem value="medical">Medical</MenuItem>
                <MenuItem value="surgical">Surgical</MenuItem>
                <MenuItem value="procedure">Procedure/Imaging</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

            {/* Display relevant services based on selected category */}
            <Typography variant="subtitle1" mt={2}>Select Services:</Typography>
            <Grid container spacing={2}>
              {getServiceOptions().map((service) => (
                <Grid item xs={12} sm={6} key={service}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={service}
                        checked={requestedServices.includes(service)}
                        onChange={handleServiceChange}
                      />
                    }
                    label={service}
                  />
                </Grid>
              ))}
            </Grid>

            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/hospital-list')}
            >
              Submit
            </Button>
          </CardContent>
          </Card>
      </Box>
    </Box>
  );
};

export default TransferRequestForm;
